/* Modal overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it appears on top */
}

/* Modal content */
.modal-content {
    background: white;
    padding: 30px;
    border-radius: 12px;
    max-width: 500px;
    max-height: 95vh;
    width: 100%;
    position: relative;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Close button */
.close-modal-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #666;
    transition: color 0.3s ease;
}

.close-modal-button:hover {
    color: #333;
}

/* Form styling */
h2 {
    margin-bottom: 30px; /* Increased padding below the heading */
    font-size: 24px;
    color: #333;
    text-align: center;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #555;
    font-size: 14px;
}

.form-input,
.form-textarea {
    width: 100%;
    padding: 12px;
    font-size: 14px;
    color: #333;
    background-color: #f9f9f9;
    transition: box-shadow 0.3s ease;
    border: none;
}

.form-input:hover,
.form-textarea:hover {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2); /* Box shadow on hover */
}

.form-input:focus,
.form-textarea:focus {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    border-color: #FFFFFF;
}

#emailBody:focus {
    box-shadow: 0;
}

#emailSubject:focus {
    box-shadow: 0;
}

#emailBody:hover {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
}

#emailSubject:hover {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
}

.form-multiselect:focus {
    border-color: #FFFFFF;
}

.form-textarea {
    resize: vertical; /* Allow vertical resizing */
    min-height: 120px;
}

/* Save button (Green) */
.save-button {
    padding: 12px 20px;
    background-color: #849E96; /* Green background */
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    transition: box-shadow 0.3s ease;
}

.save-button:hover {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2); /* Darker green on hover */
}

/* Reset to Default (Small text link) */
.reset-link {
    display: block;
    text-align: center;
    padding-top: 5px;
    margin-top: 10px;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
    transition: color 0.3s ease;
}

/* Button to open the modal */
.open-modal-button {
    padding: 12px 20px;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.reset-button {
    font-size: 14px;
    margin-top: 10px;
    text-decoration: underline;
    cursor: pointer;
    transition: color 0.3s ease;
    color:rgb(20, 20, 20);
}

.reset-button:hover {
    color:rgb(54, 54, 54); /* Darker blue on hover */
}

/* New Styles Added Below */

/* Preview Email text */
.preview-email-text {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
    cursor: pointer;
    color: #333; /* Changed to black */
    transition: color 0.3s ease;
}

.preview-email-text:hover {
    color: rgb(20, 20, 20); /* Slightly darker on hover */
}